import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavLinks.css';

const NavLinks = () => {
    const [isSubmenuVisible, setSubmenuVisible] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);

    const toggleSubmenu = (index) => {
        setSubmenuVisible(prevState => (prevState === index ? null : index));
    };

    const closeSubmenu = () => {
        setSubmenuVisible(null);
        setSelectedImages([]); // Очищаем изображения при закрытии подменю
    };

    const handleCatalogClick = () => {
        setSelectedImages([
            "/static/img/catalog1.jpg",
            "/static/img/catalog2.jpg",
            "/static/img/catalog3.jpg",
        ]);
        toggleSubmenu(0); // Показываем меню каталога
    };

    const handleCollectionClick = () => {
        setSelectedImages([
            "/static/img/collection1.jpg",
            "/static/img/collection2.jpg",
            "/static/img/collection3.jpg",
        ]);
        toggleSubmenu(1); // Показываем меню коллекций
    };

    const menuItems = [
        {
            label: "Каталог",
            links: [
                { to: "/catalog/", label: "Все товары" },
                { to: "/category/catalog/anklety/", label: "Анклеты" },
                { to: "/category/catalog/braslety/", label: "Браслеты" },
                { to: "/category/catalog/bestsellers/", label: "Бестселлеры" },
                { to: "/category/catalog/na-sheyu/", label: "На шею" },
                { to: "/category/catalog/new-arrivals/", label: "Новинки" },
                { to: "/category/catalog/svechi/", label: "Свечи" },
                { to: "/category/catalog/sertifikaty/", label: "Сертификаты" },
                { to: "/category/catalog/sergi/", label: "Серьги" },
                { to: "/category/catalog/stikery/", label: "Стикеры" },
            ],
            onClick: handleCatalogClick // Обработка клика для каталога
        },
        {
            label: "Коллекции",
            links: [
                { to: "/category/kollekczii/crystal/", label: "CRYSTAL" },
                { to: "/category/kollekczii/granny-drop/", label: "GRANNY DROP" },
                { to: "/category/kollekczii/para-nochei/", label: "PARA NOCHEI" },
                { to: "/category/kollekczii/pearl/", label: "PEARL" },
                { to: "/category/kollekczii/plan-a/", label: "PLAN “A”" },
                { to: "/category/kollekczii/stikery-kollekczii/", label: "STIKERY" },
            ],
            onClick: handleCollectionClick // Обработка клика для коллекций
        },
    ];

    return (
        <nav className="nav__bottom__container">
            <div className="bottom__container">
                <ul className="nav">
                    {menuItems.map((item, index) => (
                        <li 
                            key={index} 
                            className={`nav-link ${hoveredIndex !== null && hoveredIndex !== index ? 'inactive' : ''}`} 
                            onMouseEnter={() => setHoveredIndex(index)} 
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <span 
                                onClick={() => (item.onClick ? item.onClick() : toggleSubmenu(index))}
                                style={{ 
                                    color: isSubmenuVisible === index ? '#10059f' : (hoveredIndex === index ? '#10059f' : '#1b1b1b'),
                                }}
                            >
                                {item.label}
                            </span>
                            <div className={`submenu-content ${isSubmenuVisible === index ? 'show' : ''}`}>
                                {isSubmenuVisible === index && (
                                    <>
                                        <div className="submenu-columns">
                                            <div className="left-column">
                                                <ul className="sub-menu-left">
                                                    {item.links.slice(0, 6).map((link, i) => (
                                                        <li key={i}>
                                                            <Link to={link.to} onClick={closeSubmenu}>{link.label}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {item.links.length > 6 && (
                                                <div className="right-column">
                                                    <ul className="sub-menu-right">
                                                        {item.links.slice(6).map((link, i) => (
                                                            <li key={i}>
                                                                <Link to={link.to} onClick={closeSubmenu}>{link.label}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <div className="submenu-images">
                                            {selectedImages.map((img, i) => (
                                                <Link key={i} to="#"><img src={img} alt={`img-${i}`} style={{ width: '234px', height: '227px' }} /></Link>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </li>
                    ))}
                    <li className={`nav-link ${hoveredIndex !== null && hoveredIndex !== 'bestsellers' ? 'inactive' : ''}`} 
                        onMouseEnter={() => setHoveredIndex('bestsellers')} 
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <Link to="/bestsellers" 
                              className="nav-item" 
                              style={{ color: hoveredIndex === 'bestsellers' ? '#10059f' : '#1b1b1b' }}
                              onClick={closeSubmenu}
                        >
                            Бестселлеры
                        </Link>
                    </li>
                    <li className={`nav-link ${hoveredIndex !== null && hoveredIndex !== 'about' ? 'inactive' : ''}`} 
                        onMouseEnter={() => setHoveredIndex('about')} 
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <Link to="/about" 
                              className="nav-item" 
                              style={{ color: hoveredIndex === 'about' ? '#10059f' : '#1b1b1b' }}
                              onClick={closeSubmenu}
                        >
                            О нас
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavLinks;
