import React, { useContext } from 'react';
import { WishItemsContext } from '../../Context/WishItemsContext';
import WishCard from '../Card/Wishlist/WishCard';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './index.css';

const Wishlist = () => {
    const wishItems = useContext(WishItemsContext);

    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'избранное', path: '/wishlist' },
    ];

    return ( 
        <div className="wishlist">
            <div className="wishlist__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="wishlist__header"><h2>избранное</h2></div>
                <div className="wishlist__items__container">
                    <div className="wishlist__items">   
                    {wishItems.items.length > 0 ? wishItems.items.map((item) => <WishCard key={item._id} item={item}/>) : <>No items</>}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Wishlist;
