import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './AboutUs.css';
import desktopImage from '../../asset/img/o-nas.jpg'; // Desktop image path
import mobileImage from '../../asset/img/o-nas-mobile.jpg'; // Mobile image path

const AboutUs = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'о нас', path: '/o-nas' },
    ];

    const [imageSrc, setImageSrc] = useState(desktopImage);

    const updateImage = () => {
        if (window.innerWidth <= 768) {
            setImageSrc(mobileImage);
        } else {
            setImageSrc(desktopImage);
        }
    };

    useEffect(() => {
        updateImage();
        window.addEventListener('resize', updateImage);

        return () => {
            window.removeEventListener('resize', updateImage);
        };
    }, []);

    return (
        <div className="AboutUs">
            <div className="AboutUs__container">
                <Breadcrumbs items={breadcrumbItems} />
                <img src={imageSrc} alt='О нас' className="AboutUs__image" />
                <span className="text">
                    “Правдивость-прямолинейность” проявляется в простом и универсальном дизайне, в естественности, начиная от съемок без фотошопа и большого количества макияжа, до трансляции нашей деятельности в документальном формате, в честности с клиентом и натуральности материалов.<br /><br />
                    Свобода - мы никого не осуждаем, принимаем каждого с его выбором. Задаем вопрос, но не даем на него ответа. Вне гендера и возраста, вне политики и религии. Мы можем говорить об этом, но не занимаем определенную точку зрения. Мы всегда про поиск.<br /><br />
                    Юмор - наше отношение к жизни. Тонкая нить, которая проходит во всех креативах бренда: от продукта до визуала. Для нас юмор - простота, то, что помогает относиться проще.
                </span>
            </div>
        </div>
    );
};

export default AboutUs;
