import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import ItemCard from '../../Card/ItemCard/ItemCard'; // Adjust the import path as necessary
import ReactLoading from 'react-loading';
import Slider from "react-slick";
import './NewItems.css'; // Create a separate CSS file if needed
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const NewItems = (props) => {
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <div className="new__products__container">
            <div className="new__products">
                <div className="new__products__header">
                    <h3 className="new__items__header__big">Новинки</h3>
                    <div className="arrows">
                        <SamplePrevArrow onClick={() => sliderRef.current.slickPrev()} />
                        <SampleNextArrow onClick={() => sliderRef.current.slickNext()} />
                    </div>
                </div>
                <div className="d-flex min-vh-100 w-100 justify-content-center align-items-center m-auto">
                    {!props.items && <ReactLoading type="balls" color='#007bff' height={40} width={40} className='m-auto' />}
                   {props.items &&
                        <Slider ref={sliderRef} {...settings}>
                            {props.items.map((item, index) => (
                                <div key={index}>
                                    <ItemCard item={item} category="new" />
                                </div>
                            ))}
                        </Slider>
                    }
                </div>
            </div>
        </div>
    );
}

// Arrow components
function SampleNextArrow({ onClick }) {
    return (
        <div className="arrow next" onClick={onClick}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_122_5289)">
                    <path d="M0 12.5C0 12.8182 0.105352 13.1234 0.292883 13.3485C0.480413 13.5735 0.734758 13.6999 0.999968 13.6999H20.5856L13.2921 22.4504C13.1992 22.5619 13.1255 22.6943 13.0752 22.8399C13.0249 22.9856 12.999 23.1417 12.999 23.2994C12.999 23.4571 13.0249 23.6132 13.0752 23.7588C13.1255 23.9045 13.1992 24.0369 13.2921 24.1484C13.385 24.2598 13.4953 24.3483 13.6167 24.4086C13.738 24.4689 13.8681 24.5 13.9995 24.5C14.1309 24.5 14.261 24.4689 14.3824 24.4086C14.5038 24.3483 14.6141 24.2598 14.707 24.1484L23.7067 13.349C23.7997 13.2375 23.8735 13.1052 23.9238 12.9595C23.9741 12.8138 24 12.6577 24 12.5C24 12.3423 23.9741 12.1862 23.9238 12.0405C23.8735 11.8948 23.7997 11.7625 23.7067 11.651L14.707 0.851648C14.5194 0.626491 14.2649 0.5 13.9995 0.5C13.7342 0.5 13.4797 0.626491 13.2921 0.851648C13.1044 1.0768 12.999 1.38218 12.999 1.7006C12.999 2.01902 13.1044 2.3244 13.2921 2.54955L20.5856 11.3001H0.999968C0.734758 11.3001 0.480413 11.4265 0.292883 11.6515C0.105352 11.8765 0 12.1818 0 12.5Z" fill="#1B1B1B"/>
                </g>
                <defs>
                    <clipPath id="clip0_122_5289">
                        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

function SamplePrevArrow({ onClick }) {
    return (
        <div className="arrow prev" onClick={onClick}>
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_122_126)">
                    <path d="M24.6002 12.9264C24.5953 12.6081 24.4852 12.3046 24.2942 12.0825C24.1032 11.8604 23.8469 11.7379 23.5818 11.7421L3.9985 12.0456L11.1555 3.18311C11.2467 3.0702 11.3183 2.93672 11.3663 2.79029C11.4144 2.64386 11.4378 2.48736 11.4354 2.32972C11.4329 2.17207 11.4046 2.01637 11.3521 1.8715C11.2996 1.72663 11.2238 1.59544 11.1292 1.48541C11.0346 1.37537 10.9229 1.28866 10.8006 1.23021C10.6783 1.17176 10.5477 1.14273 10.4164 1.14476C10.285 1.1468 10.1554 1.17987 10.0349 1.24208C9.91449 1.30429 9.80558 1.39442 9.71441 1.50734L0.883164 12.4449C0.791928 12.5578 0.720232 12.6913 0.672173 12.8377C0.624114 12.9841 0.600635 13.1406 0.603079 13.2983C0.605523 13.456 0.633842 13.6117 0.686416 13.7566C0.738991 13.9015 0.814789 14.0326 0.909479 14.1426L10.0755 24.8012C10.2666 25.0235 10.523 25.146 10.7883 25.1419C11.0536 25.1378 11.3061 25.0073 11.4903 24.7793C11.6744 24.5513 11.7751 24.2443 11.7701 23.9259C11.7652 23.6075 11.6551 23.3038 11.4639 23.0816L4.03569 14.4452L23.6189 14.1416C23.8841 14.1375 24.1365 14.0072 24.3205 13.7793C24.5045 13.5514 24.6051 13.2446 24.6002 12.9264Z" fill="#1B1B1B"/>
                </g>
                <defs>
                    <clipPath id="clip0_122_126">
                        <rect width="24" height="24" fill="white" transform="matrix(0.999461 -0.0328146 -0.0328146 -0.999461 1 25.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}
export default NewItems;
