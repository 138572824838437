import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryCard.css'
import { Button } from '@mui/material';

const CategoryCard = (props) => { 
    return ( 
        <div className="category__card__card">
		        <div className="category__card__detail">
                     <Link to={props.data.url}>
					 <div className="category__name">
                       <span>{props.data.name}</span>
					   <p>украшение такое-то такое-то</p>
                     </div>
					</Link>
                </div>
                <div className="category__image"> 
                   <Link to={props.data.url}>
                      <img src= {props.data.image} alt="" className="product__img"/> 
				   </Link>
				   <div className="overlay"></div>
                </div>
        </div>
     );
}
 
export default CategoryCard;