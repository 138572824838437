import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Landing from "../components/Landing/Landing";
import FeaturedItems from "../components/Featured/Items/FetauredItems";
import FeaturedCategories from "../components/Featured/Categories/FeaturedCategories";
import ItemList from "../components/ItemList/ItemList";
import NewItems from "../components/Featured/NewItems/NewItems";
import { TabTitle } from "../utils/General";


const Home = () => {

    const [featuredItems, setFeaturedItems] = useState([]);
    const [featuredCategories, setFeaturedCategories] = useState([]);
    const [newItems, setNewItems] = useState([]); // State for new items

    TabTitle("CARABINE");

    useEffect(() => {
        // Fetch featured items
        axios.get("https://api.protonbot.ink/api/items")
            .then(res => setFeaturedItems(res.data))
            .catch(err => console.log(err));

        // Fetch featured categories
        axios.get("https://api.protonbot.ink/api/categories") // Assuming a similar endpoint for categories
            .then(res => setFeaturedCategories(res.data))
            .catch(err => console.log(err));

        // Fetch new items
        axios.get("https://api.protonbot.ink/api/items") // Assuming an endpoint for new items
            .then(res => setNewItems(res.data))
            .catch(err => console.log(err));

        window.scrollTo(0, 0);
    }, []);

    return ( 
        <Fragment>
            <Landing />
            <FeaturedItems items={featuredItems}/>
			<ItemList /> 
            <FeaturedCategories items={featuredCategories}/>
            <NewItems items={newItems} />
        </Fragment>
    );
}
 
export default Home;