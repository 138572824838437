// src/components/NotFound.js
import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './NotFound.css';

const NotFound = () => {

    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: '404', path: '#' },
    ];
    return (
        <div className="NotFound">
            <div className="NotFound__container">
        <Breadcrumbs items={breadcrumbItems} />
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404 - Страница не найдена</h1>
            <p>К сожалению, запрашиваемая страница не существует.</p>
            <a href="/">Вернуться на главную</a>
        </div>
        </div>
        </div>
    );
};

export default NotFound;
