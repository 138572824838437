import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Category from '../components/Category/Category';

const CategoryView = () => {
    const param = useParams()
    const [ planaItems, setPlanaItems ] = useState()
    const [ crystalItems, setCrystalItems ] = useState()
    const [ pearlItems, setPearlItems ] = useState()
    const [ granndydropItems, setGranndydropItems ] = useState()
    const [ paranocheiItems, setParanocheiItems ] = useState()
    const [ stikeryItems, setStikeryItems ] = useState()
    const [ loading , setLoading ] = useState(true) 

    useEffect(() => {
        axios.get("https://api.protonbot.ink/api/items")
            .then(res => {
                setPlanaItems(res.data.filter((item) => item.category === "plana"))
                setCrystalItems(res.data.filter((item) => item.category === "crystal" ))
                setPearlItems(res.data.filter((item) => item.category === "pearl")) 
                setGranndydropItems(res.data.filter((item) => item.category === "granndydrop")) 
                setParanocheiItems(res.data.filter((item) => item.category === "paranochei")) 
                setStikeryItems(res.data.filter((item) => item.category === "stikery")) 
                setLoading(false)
            })
            .catch(err => console.log(err))

        window.scrollTo(0, 0)
    }, [param.id])
    
    return ( 
        <div className='d-flex min-vh-100 w-100 justify-content-center align-items-center m-auto'>
            {loading && <ReactLoading type="balls" color='#FFE26E' height={100} width={100} className='m-auto'/>}
            { planaItems && param.id === 'plana' && <Category name="Plan “A”" items={planaItems} category="plana"/>}
            { crystalItems && param.id === 'crystal' && <Category name="Crystal" items={crystalItems} category="crystal"/>}
            { pearlItems && param.id === 'pearl' && <Category name="Pearl" items={pearlItems} category="pearl"/>}
            { granndydropItems && param.id === 'granndydrop' && <Category name="Granndy Drop" items={granndydropItems} category="granndydrop"/>}
            { paranocheiItems && param.id === 'paranochei' && <Category name="Para Nochei" items={paranocheiItems} category="paranochei"/>}
            { stikeryItems && param.id === 'stikery' && <Category name="Stikery" items={stikeryItems} category="stikery"/>}
        </div>
     );
}
 
export default CategoryView;