import { createContext } from "react";

import planaFashion from '../asset/Products/cloth/plana/plana.jpg'
import crystalFashion from '../asset/Products/cloth/crystal/crystal.jpg'
import pearlFashion from '../asset/Products/cloth/pearl/pearl.jpg'
import granndydropFashion from '../asset/Products/cloth/granndydrop/granndydrop.jpg'
import paranocheiFashion from '../asset/Products/cloth/paranochei/paranochei.jpg'
import stikeryFashion from '../asset/Products/cloth/stikery/stikery.jpg'

export const FeatureCategoryContext = createContext([
    {
        name: "Plan “A”",
        image: planaFashion,
        url: '/category/plana',
        id: 1
    },
    {
        name: "Crystal",
        image: crystalFashion,
        url: '/category/crystal',
        id: 2
    },
    {
        name: "Pearl",
        image: pearlFashion,
        url: '/category/pearl',
        id: 3
    },
    {
        name: "Granndy Drop",
        image: granndydropFashion,
        url: '/category/granndydrop',
        id: 4
    },
    {
        name: "Para Nochei",
        image: paranocheiFashion,
        url: '/category/paranochei',
        id: 5
    },
    {
        name: "Stikery",
        image: stikeryFashion,
        url: '/category/stikery',
        id: 6
    },
])