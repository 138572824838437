import React, { Fragment, useContext, useState } from 'react';
import { CartItemsContext } from '../../../Context/CartItemsContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import CartCard from './CartCard/CartCard';
import './Cart.css'
import Button from '@mui/material/Button';
import axios from 'axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '350px',
  width: '45%',
  height: '400px',
  bgcolor: 'background.paper',
  border: '5px solid #FFE26E',
  borderRadius: '15px',
  boxShadow: 24,
  p: 4,
};

const Cart = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [ openCheckoutModal, setOpenCheckoutModal] = useState(false);
    const handleCheckoutOpen = () => setOpenCheckoutModal(true);
    const handleCheckoutClose = () => setOpenCheckoutModal(false);

    const cartItems = useContext(CartItemsContext);

    const handleCheckout = async () => {
        if(cartItems.totalAmount > 0){
            const config = {
                reason: 'checkout',
                amount: cartItems.totalAmount
            }

        await axios.post("http://api.protonbot.ink/api/payment", config)
            .then((res) => {
                    console.log(res.data)
                    window.location.replace(res.data)
                    handleCheckoutOpen()
                }
            )
            .catch((err) => console.log(err))
        }
        else {
            return
        }
    }

    return (
        <Fragment>
                <Badge badgeContent={cartItems.items.length} color="error">
                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M2.30857 24.5C1.65048 24.5 1.10143 24.2947 0.661428 23.884C0.221428 23.4733 0.000952381 22.9609 0 22.3467V7.98667C0 7.37333 0.220476 6.86133 0.661428 6.45067C1.10238 6.04 1.65143 5.83422 2.30857 5.83333H5V5.16667C5 3.87067 5.48619 2.76889 6.45857 1.86133C7.43095 0.953778 8.61143 0.5 10 0.5C11.3886 0.5 12.569 0.953778 13.5414 1.86133C14.5138 2.76889 15 3.87067 15 5.16667V5.83333H17.6929C18.35 5.83333 18.8986 6.03911 19.3386 6.45067C19.7786 6.86222 19.999 7.37467 20 7.988V22.3467C20 22.96 19.7795 23.4724 19.3386 23.884C18.8976 24.2956 18.349 24.5009 17.6929 24.5H2.30857ZM2.30857 23.1667H17.6929C17.9119 23.1667 18.1133 23.0813 18.2971 22.9107C18.481 22.74 18.5724 22.5516 18.5714 22.3453V7.988C18.5714 7.78267 18.48 7.59422 18.2971 7.42267C18.1143 7.25111 17.9124 7.16578 17.6914 7.16667H15V10.5C15 10.6902 14.9319 10.8489 14.7957 10.976C14.6595 11.1031 14.4895 11.1667 14.2857 11.1667C14.0819 11.1667 13.9119 11.1031 13.7757 10.976C13.6395 10.8489 13.5714 10.6902 13.5714 10.5V7.16667H6.42857V10.5C6.42857 10.6902 6.36048 10.8489 6.22429 10.976C6.0881 11.1031 5.9181 11.1667 5.71429 11.1667C5.51048 11.1667 5.34048 11.1031 5.20429 10.976C5.06809 10.8489 5 10.6902 5 10.5V7.16667H2.30857C2.08857 7.16667 1.88667 7.252 1.70286 7.42267C1.51905 7.59333 1.42762 7.78178 1.42857 7.988V22.3467C1.42857 22.5511 1.52 22.7391 1.70286 22.9107C1.88571 23.0822 2.08714 23.1676 2.30714 23.1667M6.42857 5.83333H13.5714V5.16667C13.5714 4.228 13.2276 3.43778 12.54 2.796C11.8524 2.15422 11.0057 1.83333 10 1.83333C8.99429 1.83333 8.14762 2.15422 7.46 2.796C6.77238 3.43778 6.42857 4.228 6.42857 5.16667V5.83333Z" fill="#1B1B1B"/>
                    </svg>
                </Badge>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                    <div className="cart__header">
                        <h2>Your Cart</h2>
                    </div>
                    <div className="cart__items__container">
                        <div className="cartItems">
                            {cartItems.items.length===0? 
                                <div className="cart__empty"> Empty cart!</div> : 
                                <div className="shop__cart__items">
                                    {cartItems.items.map((item) => <CartCard key={item._id} item={item}/>)}
                                </div>
                            }
                            {cartItems.items.length > 0 &&
                                <div className="options">
                                    <div className="total__amount">
                                        <div className="total__amount__label">Total Amount:</div>
                                        <div className="total__amount__value">${cartItems.totalAmount}.00</div>
                                    </div>
                                    <div className="checkout">
                                        <Button variant="outlined" onClick={handleCheckout}>Checkout</Button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                open={openCheckoutModal}
                onClose={handleCheckoutClose}
            >
                    <Box sx={style}>
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <h2>Your checkout was successful</h2>
                    </div>
                    </Box>
                </Modal>
        </Fragment>
     );
}
 
export default Cart;