import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import './DrawerNav.css';

const ArrowSvg = ({ open }) => (
    <svg
        width="18"
        height="8"
        viewBox="0 0 18 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
        }}
    >
        <path d="M8.43925 7.81106L0.213913 1.02225C0.0766002 0.908977 -2.30183e-07 0.758483 -2.35157e-07 0.601985C-2.40132e-07 0.445487 0.0766002 0.294995 0.213913 0.18172L0.223208 0.174411C0.289774 0.119307 0.3699 0.0754294 0.45871 0.0454459C0.547521 0.0154624 0.643159 4.56393e-07 0.739808 4.53321e-07C0.836457 4.50249e-07 0.932096 0.0154624 1.02091 0.0454459C1.10972 0.0754294 1.18984 0.119307 1.25641 0.174411L9.00155 6.56732L16.7436 0.17441C16.8102 0.119307 16.8903 0.0754289 16.9791 0.0454454C17.0679 0.0154619 17.1635 -6.87319e-08 17.2602 -7.18039e-08C17.3568 -7.48761e-08 17.4525 0.0154618 17.5413 0.0454454C17.6301 0.0754289 17.7102 0.119306 17.7768 0.17441L17.7861 0.18172C17.9234 0.294994 18 0.445486 18 0.601984C18 0.758482 17.9234 0.908976 17.7861 1.02225L9.56075 7.81106C9.48841 7.87077 9.40141 7.9183 9.30502 7.95078C9.20863 7.98325 9.10486 8 9 8C8.89513 8 8.79136 7.98325 8.69497 7.95078C8.59859 7.9183 8.51159 7.87077 8.43925 7.81106Z" fill="#1B1B1B" />
    </svg>
);

const DrawerNav = () => {
    const [state, setState] = useState({
        right: false,
    });

    const [openSubmenu, setOpenSubmenu] = useState({
        catalog: false,
        collections: false,
    });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ right: open });
    };

    const toggleSubmenu = (menu) => {
        setOpenSubmenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };

    const list = () => (
        <Box
            sx={{ width: '100%', height: '100%' }} // Устанавливаем ширину и высоту на 100%
            role="presentation"
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <IconButton onClick={toggleDrawer(false)} style={{ alignSelf: 'flex-end' }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5907 9L18 16.4093L16.4093 18L9 10.5907L1.59075 18L0 16.4093L7.40925 9L0 1.59075L1.59075 0L9 7.40925L16.4093 0L18 1.59075L10.5907 9Z" fill="#1B1B1B"/>
                </svg>
            </IconButton>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => toggleSubmenu('catalog')}>
                        <ListItemText primary="Каталог" />
						<ArrowSvg open={openSubmenu.catalog} />
                    </ListItemButton>
                </ListItem>
                {openSubmenu.catalog && (
                    <List className="submenu">
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/news">
                                <ListItemText primary="Новинки" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/all">
                                <ListItemText primary="Все товары" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/bestsellers">
                                <ListItemText primary="Бестселлеры" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/anklet">
                                <ListItemText primary="Анклеты" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/sergi">
                                <ListItemText primary="Серьги" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/braslet">
                                <ListItemText primary="Браслеты" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/na-shey">
                                <ListItemText primary="На шею" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/svechi">
                                <ListItemText primary="Свечи" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/category/stickery">
                                <ListItemText primary="Стикеры" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                )}
                <ListItem disablePadding>
                    <ListItemButton onClick={() => toggleSubmenu('collections')}>
                        <ListItemText primary="Коллекции" />
						<ArrowSvg open={openSubmenu.collections} />
                    </ListItemButton>
                </ListItem>
                {openSubmenu.collections && (
                    <List className="submenu">
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/basic">
                                <ListItemText primary="BASIC" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/crystal">
                                <ListItemText primary="CRYSTAL" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/pearl">
                                <ListItemText primary="PEARL" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/granndydrop">
                                <ListItemText primary="GRANNDY DROP" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/paranochei">
                                <ListItemText primary="PARA NOCHEI" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/collections/stikery">
                                <ListItemText primary="STIKERY" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                )}
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/o-nas">
                        <ListItemText primary="О нас" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/sertificat">
                        <ListItemText primary="Сертификаты" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/magaziny">
                        <ListItemText primary="Магазины" />
                    </ListItemButton>
                </ListItem>
             </List>
            <Divider />
        </Box>
    );

    return (
        <Fragment>
            {['right'].map((anchor) => (
                <Fragment key={anchor}>
                    {state[anchor] ? (
                        <MenuOpenIcon fontSize='large' onClick={toggleDrawer(false)} />
                    ) : (
                        <MenuIcon fontSize='large' onClick={toggleDrawer(true)} />
                    )}
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(false)}
                        sx={{ '& .MuiDrawer-paper': { width: '100%', height: '100%' } }} // Ширина и высота буфера
                    >
                        {list()}
                    </Drawer>
                </Fragment>
            ))}
        </Fragment>
    );
}

export default DrawerNav;
