// src/components/Admin/AdminLogin.jsx
import React, { useState } from 'react';
import './AdminLogin.css'; // Импортируем стили

const AdminLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('admin');
  const [password, setPassword] = useState('password');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'password') {
      onLogin(true); // Успешная авторизация
    } else {
      alert('Неверное имя пользователя или пароль');
    }
  };

  return (
    <div className="admin-login">
      <h2>Войти в админ-панель</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Имя пользователя:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Пароль:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Войти</button>
      </form>
    </div>
  );
};

export default AdminLogin;
