import React, { useContext } from 'react';
import { FeatureCategoryContext } from "../../../Context/FeaturedCategoryContext";
import CategoryCard from "../../Card/FeaturedCard/CategoryCard";
import Slider from "react-slick";
import './FeaturedCategories.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Categories = () => {
    const featuredCategories = useContext(FeatureCategoryContext);

    // Настройки для слайдера
    const settings = {
        dots:false,
        infinite: false,
        speed: 500,
        slidesToShow: 1, // Можно настроить количество показываемых слайдов
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Размер для мобильных устройств
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024, // Размер для планшетов
                settings: {
                    slidesToShow: 2, // Например, показывать 2 слайда на планшете
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return ( 
        <div className="featured__categories__container">
            <div className="featured__categories">
                <div className="featured__categories__card__container">
                    {window.innerWidth < 768 ? (
                        <Slider {...settings}>
                            {featuredCategories.map((category) => (
                                <div key={category.id}>
                                    <CategoryCard data={category} />
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        featuredCategories.map((category) => (
                            <CategoryCard key={category.id} data={category} />
                        ))
                    )}
                </div>
            </div>
        </div>  
    );
}
 
export default Categories;