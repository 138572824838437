import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from '../routes/Home';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ManageAccount from '../components/Account/ManageAccount/ManageAccount';
import MyAccount from '../components/Account/MyAccount/MyAccount';
import Shop from '../components/Shop/Shop';
import ItemView from '../routes/ItemView';
import CategoryView from '../routes/CategoryView';
import SearchView from '../routes/Search';
import CartItemsProvider from '../Context/CartItemsProvider';
import Login from '../components/Authentication/Login/Login';
import Register from '../components/Authentication/Register/Register';
import Wishlist from '../components/Wishlist';
import WishItemsProvider from '../Context/WishItemsProvider';
import SearchProvider from '../Context/SearchProvider';
import NotFound from '../components/NotFound/NotFound';
import Admin from '../components/Admin/Admin'; 
import AdminLogin from '../components/Admin/AdminLogin'; 
import AboutUs from '../components/AboutUs/AboutUs';
import Offer from '../components/Offer/Offer';
import Guaranties from '../components/Guaranties/Guaranties';
import DeliveryAndPayment from '../components/DeliveryAndPayment/DeliveryAndPayment';
import ReturnsAndExchanges from '../components/ReturnsAndExchanges/ReturnsAndExchanges';
import Stores from '../components/Stores/Stores';
import FAQs from '../components/FAQs/FAQs';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <CartItemsProvider>
      <WishItemsProvider>
        <SearchProvider>
          <Router>
            <Header />
            <Routes>
              <Route index element={<Home />} />
              <Route path="/account">
                <Route path="me" element={<MyAccount />} />
                <Route path="manage" element={<ManageAccount />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="*" element={<Login />} />
              </Route>
              <Route path="/shop" element={<Shop />} />
              <Route path="/category/:id" element={<CategoryView />} />
              <Route path="/item/:gender/:id" element={<ItemView />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/search/*" element={<SearchView />} />
              <Route path="/admin" element={isAdmin ? <Admin /> : <Navigate to="/admin/login" />} />
              <Route path="/admin/login" element={<AdminLogin onLogin={setIsAdmin} />} />
              
              <Route path="/o-nas" element={<AboutUs />} />
              <Route path="/dogovor-publichnoj-oftery" element={<Offer />} />
              <Route path="/garantii" element={<Guaranties />} />
              <Route path="/dostavka-i-oplata" element={<DeliveryAndPayment />} />
              <Route path="/vozvrat-i-obmen" element={<ReturnsAndExchanges />} />
              <Route path="/magaziny" element={<Stores />} />
              <Route path="/chasto-zadavaemye-voprosy" element={<FAQs />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </SearchProvider>
      </WishItemsProvider>
    </CartItemsProvider>
  );
}

export default App;
