import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './FAQs.css';

const FAQs = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'Часто задаваемые вопросы', path: '/chasto-zadavaemye-voprosy' },
    ];

    // Состояния для каждого аккордеона
    const [openSections, setOpenSections] = useState({
        courierFAQs: false,
        sdekFAQs: false,
        selfPickup: false,
        payment: false,
		samovivozFAQs: false,
		dostFAQs: false,
		otslFAQs: false,
		vozFAQs: false,
		brackFAQs: false,
		offlineFAQs: false,
    });

    const toggleAccordion = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const ArrowIcon = ({ isOpen }) => (
        <svg
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
            <path
                d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z"
                fill="#1B1B1B"
            />
        </svg>
    );

    return (
        <div className="FAQs">
            <div className="FAQs__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="Accordion">
                    <span>Часто задаваемые вопросы</span>
                    <h1 onClick={() => toggleAccordion('courierFAQs')} style={{ cursor: 'pointer' }}>
                         ЕСЛИ МНЕ НЕ ПОДХОДИТ СТАНДАРТНЫЙ РАЗМЕР ИЗДЕЛИЯ, МОЖНО ЛИ ЕГО ИЗМЕНИТЬ? 
                        <ArrowIcon isOpen={openSections.courierFAQs} />
                    </h1>
                    {openSections.courierFAQs && (
                        <div className={`Accordion__content ${openSections.courierFAQs ? 'open' : ''}`}>
                           <p>
						      Да, мы можем сделать некоторые изделия короче или длиннее. Также все браслеты мы может сделать под ваш обхват запястья. 
						      Оставьте ваши пожелания и размеры в окошке для комментариев при оформлении заказа. Если вы уже сделали заказ и не внесли комментарии, то напишите нам в инстаграм или WhatsApp.						
						   </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <h1 onClick={() => toggleAccordion('sdekFAQs')} style={{ cursor: 'pointer' }}>
                        КАКИЕ У ВАС ЕСТЬ СПОСОБЫ ОПЛАТЫ?  
                        <ArrowIcon isOpen={openSections.sdekFAQs} />
                    </h1>
                    {openSections.sdekFAQs && (
                        <div className={`Accordion__content ${openSections.sdekFAQs ? 'open' : ''}`}>
                            <p>
                               Оплата заказа происходит на сайте при оформлении заказа банковской картой VISA / MasterCard / МИР. Также вы можете оплатить заказ наличными при получении заказа по адресу самовывоз в Москве.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('samovivozFAQs')} style={{ cursor: 'pointer' }}>
                         КАК ОФОРМИТЬ САМОВЫВОЗ?  
                        <ArrowIcon isOpen={openSections.samovivozFAQs} />
                    </h1>
                    {openSections.samovivozFAQs && (
                        <div className={`Accordion__content ${openSections.samovivozFAQs ? 'open' : ''}`}>
                            <p>
                               Вы можете написать в комментарии к заказу, что хотите самовывоз в Москве и мы свяжемся с вами. Или свяжитесь с нами в инстаграм или WhatsApp для уточнений деталей и доступного времени для самовывоза
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('dostFAQs')} style={{ cursor: 'pointer' }}>
                        ЕСТЬ ЛИ У ВАС МЕЖДУНАРОДНАЯ ДОСТАВКА? 
                        <ArrowIcon isOpen={openSections.samovivozFAQs} />
                    </h1>
                    {openSections.dostFAQs && (
                        <div className={`Accordion__content ${openSections.dostFAQs ? 'open' : ''}`}>
                            <p>
                               На данный момент мы отправляем заказы по России и в Беларусь. Но совсем скоро надеемся подключить и международную доставку
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('otslFAQs')} style={{ cursor: 'pointer' }}>
                        ГДЕ МОЖНО ОТСЛЕЖИВАТЬ ЗАКАЗ? 
                        <ArrowIcon isOpen={openSections.otslFAQs} />
                    </h1>
                    {openSections.otslFAQs && (
                        <div className={`Accordion__content ${openSections.otslFAQs ? 'open' : ''}`}>
                            <p>
                               Когда мы отправим ваш заказ, номер отслеживания придет вам на указанный вами номер телефона. 
							   В случае, если сообщение не пришло в течение 6 рабочих дней, свяжитесь с нами в Инстаграм или WhatsApp.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('vozFAQs')} style={{ cursor: 'pointer' }}>
                        КАК ОСУЩЕСТВЛЯТЬ ВОЗВРАТ ТОВАРА? 
                        <ArrowIcon isOpen={openSections.vozFAQs} />
                    </h1>
                    {openSections.vozFAQs && (
                        <div className={`Accordion__content ${openSections.vozFAQs ? 'open' : ''}`}>
                            <p>
                               Возврат осуществляется при условии сохранения товарного вида вещи в течение 30 дней с момента получения вами заказа. Возврат осуществляется с помощью транспортной компании СДЕК. Для оформления возврата свяжитесь с нами любым удобным способом.
                            </p>
                            <p>
                               Возврат обрабатывается менеджерами в течение 10 рабочих дней с момента получения заявки.
                            </p>
                            <p>
                               Подробнее о возврате читайте в разделе Возврат и обмен.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('brackFAQs')} style={{ cursor: 'pointer' }}>
                         НА ВЕЩИ ОБНАРУЖЕН БРАК, ЧТО ДЕЛАТЬ? 
                        <ArrowIcon isOpen={openSections.brackFAQs} />
                    </h1>
                    {openSections.brackFAQs && (
                        <div className={`Accordion__content ${openSections.brackFAQs ? 'open' : ''}`}>
                            <p>
							  Просим вас сделать фотографию дефекта и связаться с нами любым удобным способом. Если брак будет подтвержден, мы предложим вам сделать обмен на новое или другое украшение, или сделать возврат денежных средств. 
							  В случае обнаружения брака доставка оплачивается за наш счет.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <h1 onClick={() => toggleAccordion('offlineFAQs')} style={{ cursor: 'pointer' }}>
                        КАК УЗНАТЬ НАЛИЧИЕ В ОФФЛАЙН МАГАЗИНАХ? 
                        <ArrowIcon isOpen={openSections.offlineFAQs} />
                    </h1>
                    {openSections.offlineFAQs && (
                        <div className={`Accordion__content ${openSections.offlineFAQs ? 'open' : ''}`}>
                            <p>
							  Информацию по наличию наших изделий в оффлайн точках уточняйте, пожалуйста, напрямую в магазинах.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FAQs;
