import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css'; // Убедитесь, что у вас есть соответствующий CSS файл

const Breadcrumbs = ({ items }) => {
    return (
        <nav className="breadcrumbs">
            {items.map((item, index) => (
                <React.Fragment key={item.name}>
                    <Link to={item.path}>{item.name}</Link>
                    {index < items.length - 1 && <span className="separator">•</span>}
                </React.Fragment>
            ))}
        </nav>
    );
};

export default Breadcrumbs;
