import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './Guaranties.css';

const Guaranties = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'гарантии', path: '/garantii' },
    ];

    // Состояния для каждого аккордеона
    const [openSections, setOpenSections] = useState({
        courierGuaranties: false,
        sdekGuaranties: false,
        selfPickup: false,
        payment: false,
    });

    const toggleAccordion = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const ArrowIcon = ({ isOpen }) => (
        <svg
            width="18" 
            height="9" 
            viewBox="0 0 18 9" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
            <path d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z" 
                  fill="#1B1B1B"/>
        </svg>
    );

    return (
        <div className="Guaranties">
            <div className="Guaranties__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="Accordion">
                    <span>Гарантии</span>
                    <h1 onClick={() => toggleAccordion('courierGuaranties')} style={{ cursor: 'pointer' }}>
                        гарантийный срок и ремонт
                        <ArrowIcon isOpen={openSections.courierGuaranties} />
                    </h1>
                    {openSections.courierGuaranties && (
                        <div className={`Accordion__content ${openSections.courierGuaranties ? 'open' : ''}`}>
                            <p>
                                На наши изделия действует гарантия - 45 дней с момента покупки.<br />
                                Если изделие пришло с производственным браком или потеряло свой первоначальный вид в течение 45 дней со дня покупки - мы произведем обмен изделия на новое или вернем вам деньги. Также в течение гарантийного срока возможен бесплатный ремонт вашего украшения.
                            </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <h1 onClick={() => toggleAccordion('sdekGuaranties')} style={{ cursor: 'pointer' }}>
                        как произвести гарантийное обслуживание?
                        <ArrowIcon isOpen={openSections.sdekGuaranties} />
                    </h1>
                    {openSections.sdekGuaranties && (
                        <div className={`Accordion__content ${openSections.sdekGuaranties ? 'open' : ''}`}>
                            <p>
                                Свяжитесь с нами в&nbsp;Instagram&nbsp;или&nbsp;What’s up&nbsp;и расскажите о сложившейся ситуации. Мы обязательно вам ответим и постараемся в краткие сроки разобраться со сложившейся ситуацией.<br />
                                Гарантийное обслуживание и возврат денег не распространяются на следующие случаи:
                            </p>
                            <ul>
                                <li>На украшении есть следы механического повреждения</li>
                                <li>Были нарушены условия хранения и ухода</li>
                                <li>Изменения произошли из-за естественного износа украшения</li>
                                <li>Часть украшения: серьга, кулон или другая деталь – потеряна</li>
                                <li>С момента покупки прошло более 45 дней</li>
                            </ul>	
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Guaranties;
