import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Stores = () => {
  return (
    <div>
      <Breadcrumbs items={[{ link: '/', title: 'Главная' }, { title: 'Магазины' }]} />
      <h1>Магазины</h1>
      <p>Здесь информация о ваших магазинах...</p>
    </div>
  );
};

export default Stores;
